import { render } from "./downloadApk.vue?vue&type=template&id=7f4b85aa&scoped=true"
import script from "./downloadApk.vue?vue&type=script&lang=js"
export * from "./downloadApk.vue?vue&type=script&lang=js"

import "./downloadApk.vue?vue&type=style&index=0&id=7f4b85aa&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7f4b85aa"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7f4b85aa"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7f4b85aa', script)) {
    api.reload('7f4b85aa', script)
  }
  
  module.hot.accept("./downloadApk.vue?vue&type=template&id=7f4b85aa&scoped=true", () => {
    api.rerender('7f4b85aa', render)
  })

}

script.__file = "src/views/static/about/downloadApk.vue"

export default script