<template>
    <div class="wrapper">
        <van-overlay :show="show">
            <div class="right">
                <img src="https://static.yihu365.cn/img/h5Img/assets/img/introducer.png" alt="">
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import { ref} from "vue"
    export default {
        name: "downloadNurseApk",
        setup () {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            let ua = u.toLowerCase();
            let isWx = ua.match(/MicroMessenger/i) == 'micromessenger';

            let show = ref('true')
            if(isAndroid) {
                    location.href = "http://package.yihu365.cn/yihu365/nursehome/download/yihu_demander_2.101_2101.apk"
                }
            if(isiOS) {
                    location.href = "https://apps.apple.com/cn/app/id1223389058"
            }
            if(!isWx){
                show.value = false;
            }

            return {
                show
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
      .wrapper{
          .right{
              display: flex;
              justify-content: flex-end;
              img{
                  width: 80%;
              }
          }
      }
</style>
